<template>
<div>
  <div v-if="transmission">
    <div class="d-flex w-100">
      
      <b-button variant="none" class="transmission-img-changer" @click="userOpenFile()" :disabled="!$can('create')">
        <b-img class="image" :src="getImage(`transmission-image-${transmission.identifier}`)"/>
        <div class="edit-overlay" v-if="$can('create')">
          <b-icon icon="pencil-square" scale="1.5"/>
        </div>
      </b-button>

      <div class="pl-2 w-100">
      
        <div>
          <h3 class="mb-1 text-break">{{transmission.name}}</h3>

          <div class="d-flex justify-content-between font-weight-bold">
            <div>
              ID: <span class="font-weight-bolder">{{transmission.id}}</span>
            </div>
            <div>
              <div>
                Created at <span class="font-weight-bolder">{{formatDate(transmission.created_at)}}</span>
              </div> 
            </div>
          </div>

          <div class="d-flex justify-content-between mt-3">
            
            <b-button variant="none" class="transmission-status-btn" :class=" transmission.active ? 'active' : 'inactive' " @click="$refs['toggle-active-modal'].show()" :disabled="!$can('create')">
              <b-icon icon="circle-fill" scale="0.7" class="mr-50"/> 
              <span v-if="transmission.active">ACTIVE</span>
              <span v-else>INACTIVE</span>
            </b-button>

            <b-button :variant="transmission.is_chargeable ? 'outline-success' : 'outline-warning' " @click="$refs['toggle-chargeable-modal'].show()" :disabled="!$can('create')">
              <span v-if="transmission.is_chargeable">
                <b-icon icon="patch-check" class="mr-25"/>
                Deployed
              </span>
              <span v-else>
                <b-icon icon="code-slash" class="mr-25"/>
                In Development
              </span>
            </b-button>
          </div>

        </div>

      </div>
    </div>
    
    <div class="mt-4">
      <div v-if="userIsGod()">
        <div>Max number of Middlewares per execution:</div>
        <div>
          <b-input-group class="mb-2">
            <b-form-input class="number-hide-caret" v-model.number="editing['max_middleware'].value" type="number" :disabled="!(editing['max_middleware'].editing)" maxlength="250" @keydown.enter="saveEdit('max_middleware')"/>
            <b-input-group-append>

              <b-button variant="outline-dark" v-if="editing['max_middleware'].loading" disabled class="py-0">
                <b-spinner small variant="success"/>
              </b-button>
              <b-button v-else-if="!(editing['max_middleware'].editing)" class="py-0 px-1" variant="outline-dark" @click="promptEdit('max_middleware')">
                <b-icon icon="pencil" variant="warning"/>
              </b-button>
              <b-button v-else class="py-0 px-50" variant="outline-success" @click="saveEdit('max_middleware')">
                <b-icon icon="check" variant="success" scale="1.25"/>
              </b-button>
              
            </b-input-group-append>
          </b-input-group>                 
        </div>
      </div>

      <div>Name</div>
      <b-input-group class="mb-2">
        <b-form-input v-model="editing['name'].value" :disabled="!(editing['name'].editing)" maxlength="250" @keydown.enter="saveEdit('name')"/>
        <b-input-group-append>

          <b-button variant="outline-dark" v-if="editing['name'].loading" disabled class="py-0">
            <b-spinner small variant="success"/>
          </b-button>
          <b-button v-else-if="!(editing['name'].editing)" class="py-0 px-1" variant="outline-dark" @click="promptEdit('name')" :disabled="!$can('create')">
            <b-icon icon="pencil" variant="warning"/>
          </b-button>
          <b-button v-else class="py-0 px-50" variant="outline-success" @click="saveEdit('name')">
            <b-icon icon="check" variant="success" scale="1.25"/>
          </b-button>
          
        </b-input-group-append>
      </b-input-group>
      
      <div>Description</div>
      <div class="position-relative">
      
        <b-form-textarea class="no-resize" rows="5" maxlength="1000" v-model="editing['description'].value" :disabled="!(editing['description'].editing)" @keydown.shift.enter="saveEdit('description')" />
        
        <div class="absolute-bottom-right">
          <b-button variant="none" class="p-75" v-if="editing['description'].loading" >
            <b-spinner small variant="success"/>
          </b-button>
          <b-button variant="outline-success" class="rounded p-50 m-50" v-else-if="editing['description'].editing" @click="saveEdit('description')" >
            <b-icon icon="check" variant="success" scale="1.25"/>
          </b-button>
          <b-button variant="none" class="p-75" v-else @click="promptEdit('description')" :disabled="!$can('create')">
            <b-icon icon="pencil" variant="warning"/>
          </b-button>
        </div>

      </div>
    </div>
    
    <div class="mt-2">
      <b-button variant="outline-danger" size="sm" @click="$refs['confirm-delete-modal'].show()" :disabled="!$can('create')">
        <b-icon icon="trash" /> Delete Transmission
      </b-button>
    </div>

  </div>
  <div v-else>
    <div class="d-flex align-items-start w-100">
      <b-skeleton height="150px" width="150px"/>
      <div class="pl-2 w-100">
        <b-skeleton height="25px" width="200px"/>
        <div class="d-flex justify-content-between mt-1 w-100">
          <b-skeleton height="20px" width="100px"/>
          <b-skeleton height="20px" width="100px"/>
        </div>
        <div class="d-flex justify-content-between mt-1 w-100">
          <b-skeleton height="40px" width="100px" class="m-0"/>
          <b-skeleton height="40px" width="120px" class="m-0"/>
        </div>
      </div>
    </div>
    <div class="mt-3">
      <b-skeleton height="20px" width="100px" class="mb-50"/>
      <b-skeleton height="30px" width="100%"/>
    </div>
    <div class="mt-2">
      <b-skeleton height="20px" width="100px" class="mb-50"/>
      <b-skeleton height="150px" width="100%"/>
    </div>
  </div>

  <b-modal 
    ref="toggle-chargeable-modal"
    centered
    ok-only
    @ok="toggleChargeable()"
  >
    <template #modal-title>
      Change Transmission development status
    </template>

    <div v-if="transmission" align="center">
      
      <div v-if="transmission.is_chargeable">
        Set this Transmission to <b class="text-warning">Development</b>?
        <div class="text-secondary small">This will disable charges to this Transmission</div>
      </div>

      <div v-else>
        Set this Transmission to <b class="text-success">Deployed</b>?
        <div class="text-secondary small">This will enable charges on this Transmission</div>
      </div>
    </div>

    <template #modal-ok>
      <span class="text-black font-weight-bolder">Confirm</span>
    </template>
  </b-modal>

  <b-modal
    ref="toggle-active-modal"
    centered
    ok-only
    ok-variant="success"
    @ok="toggleStatus()"
  >
    <template #modal-title>
      <div align="center">
        Change Transmission activity status
      </div>
    </template>
    
    <div v-if="transmission" align="center">
      
      <div v-if="transmission.active">
        <h5>
          Make this Transmission <span class="text-danger font-weight-bolder">inactive</span>?
        </h5>
        
        <div class="text-secondary small">
          This will stop all executions of this Transmission
        </div>
      </div>

      <div v-else>
        <h5>
          Make this Transmission <span class="text-success font-weight-bolder">active</span>?
        </h5>
        
        <div class="text-secondary small">
          This will resume all executions of this Transmission
        </div>
      </div>      
      
    </div>

    <template #modal-ok>
      <span class="text-black font-weight-bolder">
         Confirm
      </span>
    </template>

  </b-modal>

  <b-modal
    ref="confirm-delete-modal"
    centered
    ok-variant="danger"
    ok-only
    @hide="(e)=>{if(loadingDelete) e.preventDefault()}"
    @ok="deleteTransmission()"
  >
    <template #modal-title>
      <b-icon icon="trash"/> Cofirm Delete?
    </template>
      <div v-if="transmission">
        Permanently <span class="text-danger font-weight-bolder">delete</span> the Transmission <b>{{transmission.name}}</b>?
      </div>
    <template #modal-ok>
      <span v-if="!loadingDelete">
        Confirm
      </span>
      <b-spinner v-else small/>
    </template>
  </b-modal>

</div>
</template>

<script>
import { successToast, errorToast } from "@/custom/class/FunctionClasses/CommonToasts";
import BaseModal from "@/layouts/components/BaseModal.vue";
import { makeToast } from "@/layouts/components/Popups";
import {pickAndSaveImg} from '@/custom/class/FunctionClasses/HelperFunctions.js'

import {
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormTextarea,
  BForm,
  BButton,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BRow,
  BCol,
  BSkeleton,
  BBadge,
  BImg,
  BInputGroupText,
  BCollapse,
  BSpinner,
  BModal,
} from "bootstrap-vue";
import { mapGetters } from "vuex";
import moment from 'moment';

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupText,
    BForm,
    BButton,
    BInputGroupAppend,
    BFormTextarea,
    BaseModal,
    BSkeleton,
    BBadge,
    BImg,
    BCollapse,
    BSpinner,
    BModal,
  },
  data() {
    return {
      editing: {
        name:{
          value: '',
          editing: false,
          loading: false,
        },
        description:{
          value: '',
          editing: false,
          loading: false,
        },
        max_middleware:{
          value: '',
          editing: false,
          loading: false,
        },
      },
      loadingDelete: false,
    };
  },
  props: {
    value: {
      type: [Object, null],
      default: () => null
    },
  },
  computed: {
    ...mapGetters(['getAbility', 'getImage']),
		transmission: {
			get() {
				return this.value;
			},
			set(value) {
				this.$emit('input', value);
			}
		},
    userCantEdit(){
      return !this.$can('create')
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    pickAndSaveImg,
    init() {
      this.fetchTransmissionInfo();
    },
    fetchTransmissionInfo() {
      this.$store
        .dispatch("getTransmissionGroupById", this.$route.params)
        .then(async (resp) => {
          
          // console.log('%c'+JSON.stringify(resp), 'color: limegreen');
          this.transmission = resp;
          await this.$nextTick()
    
          Object.keys(this.editing).forEach((k)=>{
            this.editing[k].value = this.transmission[k]
          })

        })
        .catch((error) => {
          console.error(error);
        });
    },
    saveMaxMidNumber(){
      let payload = {
        transmissionID: this.$route.params.transmissionID,
        newValue: parseInt(this.transmission.max_middleware)
      }
      this.$store.dispatch('changeMaxMiddlewares',payload)
        .then((resp)=>{          
          successToast({text:"Max Middlewares per execution updated"})
          this.editingMax = false
        })
        .catch((err)=>{
          this.editingMax = false
          console.error(err)
          errorToast({text:"Couldn't alter Max Middlewares per execution"})
        })
    },
    deleteTransmission() {
      this.loadingDelete = true;
      
      this.$store
        .dispatch("deleteTransmissionById", this.transmission.id)
        .then(() => {
          this.$router.push({ name: 'home' })
          makeToast({
            title: this.$t("transmission.toast.delete.success.title"),
            text: this.$t("transmission.toast.delete.success.message"),
            variant: "success",
            icon: "CheckIcon",
          }); 
          this.loadingDelete = false;
        })
        .catch((error) => {
          console.error(error)
          makeToast({
            title: this.$t("transmission.toast.delete.error.title"),
            text: this.$t("transmission.toast.delete.error.message"),
            variant: "danger",
            icon: "XIcon",
          }); 
        });
    },
    userIsGod(){
      return !!(!!this.getAbility && (parseInt(this.getAbility.id) == 666))
    },
    formatDate(d){
      let m = new moment(d)
      return m.format('l')
    },
    toggleStatus(){
      const payload = {
        transmissionID: this.$route.params.transmissionID,
        field: 'active',
        newValue: !this.transmission.active
      }
      this.$store.dispatch('patchTransmission', payload)
        .then((resp)=>{
          this.transmission = resp
          successToast({text: `Transmission status updated`})
        })
    },
    toggleChargeable(){
      const payload = {
        transmissionID: this.$route.params.transmissionID,
        field: 'is_chargeable',
        newValue: !this.transmission.is_chargeable
      }
      this.$store.dispatch('patchTransmission', payload)
        .then((resp)=>{
          this.transmission = resp
          successToast({text: `Transmission status updated`})
        })
    },
    userOpenFile(){
      pickAndSaveImg(`transmission-image-${this.transmission.identifier}`)
    },
    promptEdit(field){
      this.editing[field].editing = true
    },
    saveEdit(field){
      this.editing[field].editing = false
      this.editing[field].loading = true
      
      const payload = {
        transmissionID: this.$route.params.transmissionID,
        field: field,
        newValue: this.editing[field].value
      }
      this.$store.dispatch('patchTransmission', payload)
        .then((resp)=>{
          successToast({text: `Transmission ${field} updated`})
          this.editing[field].loading = false
          this.transmission = resp
        })
    }
    
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/custom-utils.scss';

.absolute-bottom-right{
  position: absolute;
  bottom: 0;
  right: 0;
}

.no-resize{
  resize: none;
}

.transmission-img-changer{
  $img-size: 150px;
  position: relative;
  width: $img-size;
  height: $img-size;
  min-width: $img-size;
  max-width: $img-size;
  padding: 0;
  border-radius: 6px;
  border: 1px solid transparentize($light, 0.9);
  &:hover{
    .edit-overlay{
      opacity: 0.8;
    }
  }
  &:active{
    color: $light;
  }
  .image{
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    background-color: transparentize($black, 0.7);
  }

  .edit-overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparentize($black, 0);
    transition: opacity 0.2s;
    opacity: 0;
  }
}

.transmission-status-btn{
  transition: all 0.2s;
  padding: 5px 10px;
  
  @mixin status-btn($btn-color){
    font-weight: bolder;
    color: $btn-color;
    background-color: transparentize($btn-color, 0.8);
    outline: 1px solid transparent;
    &:hover, &:focus{
      outline-color: transparentize($btn-color, 0.5);
    }
    &:active{
      outline-color: $btn-color;
    }
  }

  &.active{
    @include status-btn($success);
  }
  &.inactive{
    @include status-btn($danger);
  }
}

</style>