<template>
<v-select 
  placeholder="Ex: username..." 
  label="username"
  :multiple="multiple"
  :loading="loading"
  :options="userOptions"
  @search="userInput"
  @input="$emit('change')"
  v-model="vModel"
>
  <!-- <template #no-options>
    <span></span>
  </template> -->
</v-select>
</template>

<script>
import VSelect from 'vue-select'

export default {
  components: {
    VSelect,
  },
  data() {
    return {
      searchTimeout: undefined,
      loading: false,
      userOptions: []
    }
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    multiple:{
      type: Boolean,
      default: false
    }
  },
  computed: {
		vModel: {
			get() {
				return this.value;
			},
			set(value) {
				this.$emit('input', value);
			}
		},
  },
  mounted () {
    this.fetchUsers('');
  },
  methods: {
    userInput(term) {
      clearTimeout(this.searchTimeout)
      this.searchTimeout = setTimeout(() => {
        this.fetchUsers(term)
      }, 500);
    },
    fetchUsers(term){
      this.loading = true;
      this.$store.dispatch('getUserByUsername',term)
        .then((resp)=>{
          this.userOptions = resp
        })
        .finally(()=>{
          this.loading = false
        })
    }
  },
}
</script>

<style lang="scss" scoped>

</style>