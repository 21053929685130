<template>
<div>
  <b-row no-gutters>
    
    <b-col cols="6" class="border-right-secondary pr-2">
      <transmission-config v-model="transmission"/>
    </b-col>

    <b-col cols="6" class="pl-2">
      <transmission-notifications v-model="transmission"/>
    </b-col>

  </b-row>
  
</div>
</template>

<script>
import TransmissionConfig from "@/views/pages/transmission/TransmissionConfig.vue";
import TransmissionNotifications from '@/views/pages/transmission/TransmissionNotifications.vue';


import {
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormTextarea,
  BForm,
  BButton,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BRow,
  BCol,
  BSkeleton,
  BBadge,
  BImg,
  BInputGroupText,
  BCollapse,
} from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupText,
    BForm,
    BButton,
    BInputGroupAppend,
    BFormTextarea,
    BSkeleton,
    BBadge,
    BImg,
    BCollapse,
    TransmissionConfig,
    TransmissionNotifications
  },
  data() {
    return {
      transmission: null
    }
  },
};
</script>
