<template>
<div>

  <div v-if="transmission">
    <h3 class="mb-3">
      <b-icon icon="bell-fill"/>
      Notifications
    </h3>
    
    <div class="d-flex align-items-center mb-2">
      <b-form-checkbox switch v-model="transmission.notifications.critical_error" @change="debounceInput('critical_error')"/>
      <span>
        Allow Notifications for this Transmission 
        <span class="small"> - we'll notify you of any <b>Critical Errors</b></span>
      </span>
    </div>

    <div :class="{'area-disabled': !transmission.notifications.critical_error}">
      <div class="d-flex align-items-center mb-5">
        <b-form-checkbox switch v-model="transmission.notifications.non_critical_error" :disabled="!transmission.notifications.critical_error" @change="debounceInput('non_critical_error')"/>
        <span>Allow Notifications for <b>Non-Critical Error</b> </span>
      </div>
      
      <div class="mb-2">
        <div class="m-0">Emails to notify</div>
        <v-select
          multiple 
          appendToBody
          placeholder="Ex: client@gmail.com..." 
          :noDrop="!emailSelect.options || !emailSelect.options.length"
          :options="emailSelect.options" 
          v-model="transmission.notifications.email" 
          @search="emailInput" 
          @input="debounceInput('email')"
        >
          <template #no-options>  
            <span>Insert a valid Email</span>
          </template>
        </v-select>
      </div>
      
      <div>
        <div class="mb-2">
          <div class="m-0">Users to notify</div>
          <users-input multiple v-model="transmission.notifications.user" @change="debounceInput('user')"/>
        </div>
      </div>


      <div>
        <div class="mb-2">
          <div class="m-0">Webhook to call when a Notification is sent </div>
          <b-form-input v-model="transmission.notifications.webhook[0]" placeholder="Webhook url..." @change="debounceInput('webhook')"/>
        </div>
      </div> 
    </div>
  </div>
  <div v-else>
    <b-skeleton height="30px" width="200px" class="mb-3"/>
    
    <b-skeleton height="25px" width="300px"/>
    <b-skeleton height="25px" width="300px" class="mb-5"/>

    <b-skeleton height="20px" width="100px" class="mb-50"/>
    <b-skeleton height="40px" width="100%" class="mb-2"/>

    <b-skeleton height="20px" width="100px" class="mb-50"/>
    <b-skeleton height="40px" width="100%" class="mb-2"/>

    <b-skeleton height="20px" width="200px" class="mb-50"/>
    <b-skeleton height="40px" width="100%" class="mb-2"/>
  </div>
  <!-- {{transmission}} -->
</div>
</template>

<script>
import VSelect from 'vue-select'
import UsersInput from "@/views/pages/middleware/MiddlewareManagement/Components/UsersInput.vue"
import {
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormTextarea,
  BForm,
  BButton,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BRow,
  BCol,
  BSkeleton,
  BBadge,
  BImg,
  BInputGroupText,
  BCollapse,
} from "bootstrap-vue";
import { errorToast, successToast } from '@/custom/class/FunctionClasses/CommonToasts';

export default {
  components: { 
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormTextarea,
    BForm,
    BButton,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BRow,
    BCol,
    BSkeleton,
    BBadge,
    BImg,
    BInputGroupText,
    BCollapse,
    VSelect,
    UsersInput,
  },
  data() {
    return {
      emailSelect:{
        options: [],
        value: undefined,
      },
      usersSelected: undefined,
      webhook: "",
      timeout: {},
    }
  },
  props: {
    value: {
      type: [Object, null],
      default: null
    },
  },
  computed: {
		transmission: {
			get() {
				return this.value;
			},
			set(value) {
				this.$emit('input', value);
			}
		},
  },
  methods: {
    emailInput(term) {
      const isValidEmail = !!(String(term).toLowerCase().match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      ));
      
      if (isValidEmail){
        this.emailSelect.options = [term]
      } else {
        this.emailSelect.options = []
      }
    },
    debounceInput(field){
      clearTimeout(this.timeout[field])
      this.timeout[field] = setTimeout(() => {
        this.saveField(field)
      }, 3000);
    },
    saveField(field){ 
      let payload = {
        transmissionID: this.transmission.id,
        field: field,
        newValue: this.transmission.notifications[field],
      }
      this.$store.dispatch('patchNotificationField', payload)
      .then((resp)=>{
        successToast({text: "Notification settings updated"})
      })
        .catch((err)=>{
          errorToast({text: "Couldn't update Transmission"})
          console.error(err)
        })
    }
  },
}
</script>

<style lang="scss" scoped>
.area-disabled{
  cursor: not-allowed !important;
  pointer-events: none !important;
  opacity: 0.5;
  *{
    pointer-events: none !important;  
  }
}
</style>
